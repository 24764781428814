import React from 'react';

const CheckboxItem = ({ value, fieldName, checked, title, includedInBase, price, descriptionText, onChange }) => {

  const dynamicClassName = () => {
    return (checked) ? "option selected" : "option";
  }

  return (
    <li className={(checked) ? "option selected" : "option"}>
      <input
        type="checkbox"
        name={fieldName}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="option_text">{title}</span>
      <span className="includedInBasePrice">{ (includedInBase) ? "yes" : "no" } </span>
      <span className="price_change_container">
        <span className="price_change_text">+ ${price.toLocaleString()}</span>
      </span>
      { descriptionText !== "" && (
        <span className="descriptionText">{descriptionText}</span>
      )}
    </li>
  );
};

export default CheckboxItem;