import React, { useState } from 'react';
import './App.css';
import _ from 'lodash';
import OptionContainer from './components/OptionContainer'
import { createSelector } from 'reselect';
import { filter, flatMap, sum, map } from 'lodash/fp';
import BuilderForm from './components/BuilderForm';
import SelectedContainer from './components/SelectedContainer';

const notIncludedInBase = filter(({includedInBase}) => !includedInBase);
const selected = filter('selected');
const optionsOf = flatMap('options');
const itemsOf = flatMap('items');
const prices = map('price');

const updateConfigPrice = createSelector(_.identity, (config) => {
  const {basePrice, features} = config;
  updateImage(config);
  return basePrice + sum(prices(selected(notIncludedInBase(optionsOf(itemsOf(features))))));
});

// const clickSubmit = (config) => {
//   console.log(config)
  
// }
const updateImage = (config) => {
  config.features.forEach((g) => {
    g.items.forEach((feat) => {
      if (feat.image_layer !== null && feat.image_layer !== "") {
        let layerId = feat.image_layer;
        let selected_class = "fill ";
        feat.options.forEach((opt) => {
          if (opt.selected === true) {
            selected_class += opt.image_layer_option;
          }
        });
        let theDiv = document.getElementById(layerId);
        if (theDiv) {
          theDiv.className = selected_class;
        }
      }
    });
  });
};

const calculatePrice = createSelector(_.identity, (config) => ({
  ...config,
  configPrice: updateConfigPrice(config)
}));

function App({initialConfig}) {
  const [config, setConfig] = useState(initialConfig);
  const configWithPrice = calculatePrice(config);
  
  const setBuilder = createSelector(_.identity, (newBuilder) => {
    setConfig({ ...config, builder: newBuilder })
  
  });

  const saveURL = () => {
    const domain = (process.env.REACT_APP_BACKEND_DOMAIN) ? process.env.REACT_APP_BACKEND_DOMAIN : "https://build.chittumskiffs.com"
    return `${domain}/api/v1/teams/1/prospects.json`
  }

  const requestQuote = () => {
    if (completeVisitorRecord()) {
      config.builder.requests_quote = true
      clickSubmit()
    }
  }

  const clickSubmit = () => {
    if (completeVisitorRecord()) {
      const newObject = {
        raw_skiff: config
      }
      fetch(saveURL(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newObject)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('We had a problem saving the data: ' + response.statusText)
        }
        return response.json()
      })
      .then(data => {
        console.log("Save success: ", data)
      })
      alert(`Thank you for saving your skiff, ${config.builder.first_name}. We look forward to working with you to build your dream skiff.`)
      document.getElementById("submitMessage").innerHTML("Your skiff has been saved.  We will be in touch with you soon.")
    }
    else {
      alert(`You must complete the personal information portion of the form prior to saving it.`)
    }
  }

  const completeVisitorRecord = () => {
    if (config.builder.first_name === "" ||
        config.builder.last_name === "" ||
        config.builder.address === "" ||
        config.builder.city === "" ||
        config.builder.state === "" ||
        config.builder.zip === "" ||
        config.builder.email === "" ||
        config.builder.phone === "") {
          console.log("Incomplete visitor form.")
      return false
    }
    else {
      return true
    }
  }

  return (
    <div className="configurator-instance">
      <div id="header">{config.baseName}</div>
      <div id="primary-container">
        <div id="feedback-container">
          <div id="image">
            <img src="https://chittum-skiffs.s3.amazonaws.com/18-ft-1800x1350/chi18_base.png" />
            <div class="fill" id="nonskid"></div>
            <div class="fill" id="liner"></div>
            <div class="fill" id="deck_color">
              <div class="fill" id="hatch"></div>
            </div>
            <div class="fill" id="hull_color">
              <div class="fill degree2" id="degrees"></div>
            </div>
            <div class="fill" id="console_color">
              <div class="fill" id="console"></div>
            </div>
            <div class="fill tohatsu60" id="engine"></div>
          </div>
          <div id="pricing">
            <div id="base_pricing">Base price:
              <span id="base_price">
              {config.basePrice !== null && config.basePrice !== undefined 
                ? `$${config.basePrice.toLocaleString()}` 
                : 'Price not available'}
              </span>
            </div>
            <div id="config_pricing">As configured: 
              <span id="config_price">
              {config.basePrice !== null && config.basePrice !== undefined 
                ? `$${config.configPrice.toLocaleString()}` 
                : 'Price not available'}
              </span>
            </div>
          </div>
          
          <div id="my-boat">
            <SelectedContainer config={configWithPrice} />
          </div>
        </div>
        <div className="option-container">
          <OptionContainer config={configWithPrice} onConfigChange={setConfig}/>
          <div id="personal">
            <BuilderForm builder={config.builder} onBuilderChange={setBuilder} />
            <div id="submitMessage">
              &nbsp;
            </div>
            <div class="submit">
              <button id="submit" type="submit" onClick={clickSubmit}>Save my skiff</button>
              <button id="submit2" type="submit" onClick={requestQuote}>Request a quote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;