import React, { useState } from 'react';

const BuilderForm = ({ builder, onBuilderChange }) => {

  const handleChange = (e) => {
    if (builder) {
      const { name, value } = e.target;
      onBuilderChange({ ...builder, [name]: value})
    }
  }

  return (
    <div className="formWrapper">
      <h1>Your contact info:</h1>
      <p>Using this form is a start on your path to owning exactly the skiff you want.  Please let us who about you, so that our sales team can connect with you and begin building your dream boat. </p>
      <div className="halfwide">
        <span className="inputTitle ">First Name <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="first_name"
          value={builder?.first_name || ''}
          onChange={handleChange}
        />
      </div>
      <div className="halfwide">
        <span className="inputTitle ">Last Name <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="last_name"
          value={builder?.last_name || ''}
          onChange={handleChange}
        />
      </div>
      <div className="fullwide">
        <span className="inputTitle ">Email <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="email"
          value={builder?.email || ''}
          onChange={handleChange}
        />
      </div>
      <div className="fullwide">
        <span className="inputTitle ">Phone <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="phone"
          value={builder?.phone || ''}
          onChange={handleChange}
        />
      </div>
      <div className="fullwide">
        <span className="inputTitle ">Address <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="address"
          value={builder?.address || ''}
          onChange={handleChange}
        />
      </div>
      <div className="halfwide">
        <span className="inputTitle ">City <span class="required">*</span></span>
        <input
          className=""
          type="text"
          name="city"
          value={builder?.city || ''}
          onChange={handleChange}
        />
      </div>
      <div className="halfwide">
        <span className="inputTitle ">State/Zip <span class="required">*</span></span>
        <input
          className="state"
          type="text"
          name="state"
          value={builder?.state || ''}
          onChange={handleChange}
        />
        <input
          className="zip"
          type="text"
          name="zip"
          value={builder?.zip || ''}
          onChange={handleChange}
        />
      </div>
      <div className="fullwide">
        <span className="inputTitle ">Notes</span>
        <textarea
          className=""
          name="notes"
          value={builder?.notes || ''}
          onChange={handleChange}
          rows={5}
          cols={45}
        />
      </div>
    </div>
  );
};

export default BuilderForm;