import React from 'react';
import _ from 'lodash';
import FeatureRadioGroup from './FeatureRadioGroup';
import FeatureCheckboxGroup from './FeatureCheckboxGroup';
import FeatureImageRadioGroup from './FeatureImageRadioGroup';

const FeatureCategory = ({feature, onFeatureChange}) => {
  const {title, expanded, items} = feature;

  const setFeatureItem = (item) => (update) => {
    if (_.isFunction(update)) {
      update = update(item);
    }

    onFeatureChange((feature) => {
      return {
        ...feature,
        items: _.map(feature.items, (cItem) => {
          return cItem === item ? update : cItem;
        })
      };
    });
  }

  const setFeatureExpanded = (_e) => {
    onFeatureChange((feature) => {
      return {
        ...feature,
        expanded: !feature.expanded
      }
    })
  }

  return (
      <div className="feature_category">
        <h2
        onClick={setFeatureExpanded}>
          {feature.title}
          <span
            className="expandGroup">
              {feature.expanded  ? "-" : "+"}
          </span>
        </h2>

        {expanded && _.map(items, (item) => {
            if (item.type === 'single-select') {
              return (
                <FeatureRadioGroup 
                  featureItem={item}
                  onItemChange={setFeatureItem(item)}/>
              );
            } else if (item.type === 'single-select-image') {
              return (
                <FeatureImageRadioGroup 
                  featureItem={item}
                  onItemChange={setFeatureItem(item)}/>
              );
            } else if (item.type === 'multiselect') {
              return (
                <FeatureCheckboxGroup 
                  featureItem={item}
                  onItemChange={setFeatureItem(item)}/>
              );
            }
          })}
      </div>
  );
};

export default FeatureCategory;